export const SERVER_ERROR = 'Unable to process your request. Please try again, or contact JDoodle Support at hello@jdoodle.com.'

export const JD_CONST = {
  SIGN_IN: 'SignedIn'
}

export const IDE_CONST = {
  OUTPUT_EDITOR: 'output',
  CODE_EDITOR: 'code',
  FILE_EDITOR: 'myFilesEditor',
  HTML_DOC_TYPE_EDITOR_NAME: 'html-doctype-code',
  HTML_BODY_EDITOR_NAME: 'html-body-code',
  HTML_HEAD_EDITOR_NAME: 'html-head-code',
  JS_CODE_EDITOR_NAME: 'js-code',
  CSS_CODE_EDITOR_NAME: 'css-code'
}

export const ROBOT_CONST = {
  VALIDATE_ROBOT_CHECK: 'ValidateRobotCheck',
  ROBOT_ERROR: 'Your request seems invalid, please try again.',
  ROBOT_IDE_ERROR: 'Your request seems invalid. Please try to execute again.\n\nIf the problem persists, please try the below solutions: \na) refresh the page \nb) clear the cache \nc) login\nd) try in incognito mode\ne) contact JDoodle support - hello@jdoodle.com'
}

export const CMD_LANGUAGES = ['sql', 'brainfuck', 'hack', 'yabasic', 'verilog', 'whitespace', 'nasm', 'gccasm', 'falcon', 'factor', 'spidermonkey', 'rhino']

export const SYNC_ACTIONS = {
  NEW_ITEM: 'new_item',
  RENAME: 'rename',
  FILE_CHANGED: 'file_changed',
  DELETE: 'delete',
  ITEM_MOVED: 'item_moved'
}

export const GURU_USER_STATUS = {
  PENDING_USER_ACCEPTANCE: 'Pending User Acceptance',
  ACTIVE: 'Active',
  IN_ACTIVE: 'Inactive',
  REJECT: 'Rejected',
  EXITED: 'Exited'
}

export const GURU_ROLES = {
  ACCOUNT_OWNER: 'Account Owner',
  INSTITUTE_ADMIN: 'Institute Admin',
  TEACHER: 'Teacher',
  STUDENT: 'Student'
}

export const GURU_FORM_STATUS = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
  CLONE: 'clone'
}

export const GURU_EVENTS = {
  GURU_INIT: 'GuruInit',
  SHOW_IN_SIDEBAR: 'showInSideBar',
  ADD_TO_STUDENT_GROUP: 'AddToStudentGroup',
  REMOVE_FROM_STUDENT_GROUP: 'RemoveFromStudentGroup',
  REPLACE_IN_STUDENT_GROUP: 'ReplaceInStudentGroup',
  ADD_TO_USERS_LIST: 'AddToUsersList',
  REMOVE_FROM_USERS_LIST: 'RemoveFromUsersList',
  REPLACE_IN_USERS_LIST: 'ReplaceInUsersList',
  DELETE_STUDENT_GROUP: 'GuruDeleteStudentGroup',
  CONFIRMATION_ACTION_SUCCESS_NOTIFICATION: 'ConfirmationActionSuccessNotification',
  CONFIRMATION_ACTION_FAILED_NOTIFICATION: 'ConfirmationActionFailedNotification',
  ADD_STUDENT_TO_STUDENT_GROUP: 'GuruAddStudentToStudentGroup',
  ADD_ADMIN_TO_STUDENT_GROUP: 'GuruAddAdminToStudentGroup',
  REMOVE_STUDENT_FROM_STUDENT_GROUP: 'GuruRemoveStudentFromStudentGroup',
  REMOVE_ADMIN_FROM_STUDENT_GROUP: 'GuruRemoveAdminFromStudentGroup',
  USER_INCLUDED_NOTIFICATION: 'GuruUserIncludedNotification',
  USER_INCLUDE_FAILED_NOTIFICATION: 'GuruUserIncludeFailedNotification',
  STUDENT_GROUP_INCLUDED_NOTIFICATION: 'GuruStudentGroupIncludedNotification',
  STUDENT_GROUP_INCLUDE_FAILED_NOTIFICATION: 'GuruStudentGroupIncludeFailedNotification',
  ADD_TO_ASSIGNMENTS: 'AddToAssignments',
  UPDATE_IN_ASSIGNMENT: 'UpdateInAssignment',
  ADD_TEACHER_TO_ASSIGNMENT: 'AddTeacherToAssignment',
  REMOVE_TEACHER_FROM_ASSIGNMENT: 'RemoveTeacherFromAssignment',
  ADD_STUDENT_GROUP_TO_ASSIGNMENT: 'AddStudentGroupToAssignment',
  REMOVE_STUDENT_GROUP_FROM_ASSIGNMENT: 'RemoveStudentGroupFromAssignment',
  ADD_STUDENT_TO_ASSIGNEMNT: 'GuruAddStudentToAssignment',
  REMOVE_STUDENT_FROM_ASSIGNMENT: 'GuruRemoveStudentFromAssignment',
  DELETE_ASSIGNMENT: 'GuruDeleteAssignment',
  ON_HOLD_ASSIGNMENT: 'GuruOnHoldAssignment',
  ADD_QUESTION_TO_ASSIGNEMNT: 'GuruAddQuestionToAssignment',
  UPDATE_QUESTION_IN_ASSIGNMENT: 'GuruUpdateQuestionInAssignment',
  DELETE_QUESTION_IN_ASSIGNMENT: 'GuruDeleteQuestionInAssignment',
  LOAD_QUESTION_TO_ATTEND: 'GuruLoadQuestionToAttend',
  AUTO_EVALUATE_ASSIGNMENT_AGAIN: 'GuruAutoEvaluateAssignmentAgain',
  SUBMIT_ASSIGNMENT: 'GuruSubmitAssignment',
  REQUEST_FOR_REEVALUATION: 'GuruRequestForRevaluation',
  MARK_AS_EVALUATED: 'GuruMarkAsEvaluated',
  DISCLOSE_RESULT: 'GuruDiscloseResult',
  REOPEN_ASSESSMENT: 'GuruReOpenAssessment'
}

export const GURU_CONSTANTS = {
  ASSIGNMENT_STUDENT_STATUS: {
    YET_TO_START: 'Yet to Start',
    IN_PROGRESS: 'In Progress',
    SUBMITTED: 'Submitted',
    ERROR_WHEN_AUTO_CORRECT: 'Unable to Auto Correct',
    EVALUATED: 'Evaluated',
    PARTIALLY_EVALUATED: 'Partially Evaluated',
    RESULT_ANNOUNCED: 'Result Announced',
    REEVALUATION_REQUESTED: 'Reevaluation Requested'
  },
  ASSIGNMENT_STATUS: {
    DRAFT: 'Draft',
    READY: 'Ready',
    ON_HOLD: 'On Hold',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed'
  },
  QUESTION_TYPES: [
    { type: 'Select Type', value: -1 },
    { type: 'Multiple Choice - One Answer', value: 0 },
    { type: 'Multiple Choice - Multiple Answer', value: 1 },
    { type: 'Open Ended', value: 2 },
    { type: 'Program', value: 3 },
    { type: 'True Or False', value: 4 }
  ],
  COURSE_QUIZ_QUESTION_TYPES: [
    { type: 'Select Type', value: -1 },
    { type: 'Multiple Choice - One Answer', value: 0 },
    { type: 'Multiple Choice - Multiple Answer', value: 1 },
    { type: 'True Or False', value: 2 }
  ],
  RESULT_TYPES: [
    { type: 'Mark and Comment', value: 0 },
    { type: 'Mark Only', value: 1 },
    { type: 'Comment Only', value: 2 }
  ],
  DISCLOSE_OPTIONS: [
    { 'option': 'Manual', value: 0 },
    { 'option': 'On Assignment Submission', value: 1 }
  ],
  ASSIGNMENT_TYPES: {
    ASSIGNMENT: 'assignment',
    GLOBAL_LIB: 'global-lib',
    INS_LIB: 'library'
  },
  COURSE_TYPES: {
    COURSE: 'course',
    GLOBAL_LIB: 'global-lib',
    INS_LIB: 'library'
  },
  COURSE_STUDENT_STATUS: {
    YET_TO_START: 'Yet to Start',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed'
  }
}
