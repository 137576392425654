import { SERVER_ERROR } from './constants'

export default {
  data: function () {
    return {
      doodleForm: {
        inProgress: false,
        completed: false,
        errorMessage: null
      }
    }
  },
  computed: {
    showMainSection () {
      return !this.doodleForm.inProgress && !this.doodleForm.completed
    },
    showInProgressSection () {
      return this.doodleForm.inProgress
    }
  },
  methods: {
    executeAPIWitoutValiation: function (apiRequest, count = 0) {
      if (!this.$store.state.isAppInitiated) {
        if (count > 25) {
          apiRequest.form.errorMessage = 'Unable to initiate JDoodle App. Please refresh the page and try again.'
          return
        }

        this.$_.delay(this.executeAPIWitoutValiation, 100, apiRequest, count + 1)
        return
      }

      apiRequest.form.completed = false
      apiRequest.form.errorMessage = null
      apiRequest.form.inProgress = true
      let payload = {
        url: apiRequest.url,
        method: apiRequest.method,
        data: apiRequest.data
      }
      if (apiRequest.contentType) {
        payload.contentType = apiRequest.contentType
      }
      $.ajax(payload).fail((jqXHR, textStatus, errorThrown) => {
        if (jqXHR.status >= 500 || jqXHR.status === 404) {
          apiRequest.form.errorMessage = SERVER_ERROR
        } else if (jqXHR.status === 401) {
          apiRequest.form.errorMessage = 'Your session expired, please login and try again'
          this.$store.commit('logout')
          this.$_.delay(() => {
            this.$router.push('/')
          }, 5000, '')
        } else {
          apiRequest.form.errorMessage = jqXHR.responseJSON.message
        }
        if (apiRequest.failureAction) {
          apiRequest.failureAction(jqXHR.status)
        }
        window.jda.exception(apiRequest.url + ': ' + errorThrown)
      }).done((data) => {
        if (apiRequest.markCompleted) {
          apiRequest.form.completed = true
        }
        if (apiRequest.successAction) {
          apiRequest.successAction(data)
        }
      }).always(() => {
        apiRequest.form.inProgress = false
      })

      if (apiRequest.jdaCategory) {
        window.jda.pageEvent(apiRequest.jdaCategory, apiRequest.jdaEvent, apiRequest.jdaLabel)
      }
    },
    executeAPI (apiRequest) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.executeAPIWitoutValiation(apiRequest)
        }
      })
    }
  }
}
