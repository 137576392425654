<template>
  <div>
    <DoodleGeneralForm title="Registration Verification"
                       in-progress-message="JDoodle is trying to verify your registration, Please wait..."
                       success-message="Your e-mail verified successfully. Thanks for using JDoodle."
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @close="goBack">
      <template v-slot:errorMessage >
        <div class="has-text-weight-bold error-message register-followup" v-show="doodleForm.isFailed">
          <div class="has-text-centered has-text-danger">Unable to verify your registration </div><br/>
          1. Please check the link is valid. <br/>
          2. This is one time use link. If you have already used the link, your email should have been verified already. You can login with your username and password.<br/>
          3. If your account is locked, please try with unlock account link below. <br/>
          4. Please contact JDoodle Support for any assistance.<br/>
          <div class="has-text-centered has-text-grey">Thanks for using JDoodle.</div>
        </div>
      </template>
      <template v-slot:successMessage >
        <div class="has-text-centered">
        <font-awesome-icon icon="exclamation-circle" class="is-size-3 has-text-info"/> <br/>
        <span class="has-text-weight-bold is-size-6-desktop is-size-7-mobile">
            Your e-mail verified successfully. <br/>
            Thanks for using JDoodle.
          </span>
        </div>
      </template>
    </DoodleGeneralForm>
  <div class="container level has-background-white-ter doodle-display-box" v-show="!doodleForm.inProgress && doodleForm.isFailed">
    <div class="level-item">
      <router-link to="/" class="button is-info">
        Home
      </router-link>
    </div>
    <div class="level-item">
      <router-link to="/unlock" class="button is-info">
        Unlock Account
      </router-link>
    </div>
  </div>
  </div>
</template>

<script>

import formMixin from '../assets/javascript/form-mixin'
import DoodleGeneralForm from '../components/Utils/DoodleGeneralForm'
import backButtonMixin from '@/assets/javascript/back-button-mixin'

export default {
  name: 'logout',
  mixins: [formMixin, backButtonMixin],
  components: { DoodleGeneralForm },
  data: function () {
    return {
      doodleForm: {
        isFailed: false
      }
    }
  },
  mounted () {
    let t = this.$route.query.t

    let failureAction = () => {
      this.doodleForm.errorMessage = 'Sorry, we have no record with this registration code, or it has expired.'
      this.doodleForm.isFailed = true
      this.doodleForm.completed = true
    }

    this.doodleForm.registrationCode = this.$route.query.t

    if (!t) {
      failureAction()
      return
    }

    this.executeAPIWitoutValiation({
      url: '/api/register/verifyRegistration',
      data: {
        't': t },
      method: 'post',
      successAction: (data) => {
        this.$store.commit('login', data.username)
        this.doodleForm.completed = true
      },
      failureAction: failureAction,
      markCompleted: true,
      form: this.doodleForm,
      jdaCategory: window.jda.CATEGORY.GLOBAL,
      jdaEvent: 'verify-registration',
      jdaLabel: ''
    })
  }
}
</script>

<style scoped lang="scss">
  .logout-error-message {
    margin-top: 3em;
    padding: 3em;
  }
  .error-message {
    margin-left: 2em;
  }
  .register-followup {
    color: chocolate;
    line-height: 1.5em;
    font-style: italic;
    font-family: serif;
    font-size: 1em;
    text-align: left;
  }
  .success-message {
    margin-top: 3em;
    margin-bottom: 3em;
  }

</style>
