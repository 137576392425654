export default {
  data: function () {
    return {
      lastRoute: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.lastRoute = from.name
      next()
    })
  },
  methods: {
    goBack () {
      if (this.lastRoute != null) {
        this.$router.go(-1)
      } else {
        this.$router.push('/')
      }
    }
  }
}
